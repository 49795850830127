import React, { ReactElement } from "react";
import { graphql } from "gatsby";
import {
  GatsbyPageProps,
  FixedSharpImageFile,
  AllFileQueryResult,
} from "@ymcansw-camping/common";
import { TeamMember } from "../../components/team-member-row";
import OurCampLayout from "../../components/our-camp-layout";

type OurTeamProps = GatsbyPageProps<
  AllFileQueryResult<TeamMember> & {
    readonly bannerHeaderImage: FixedSharpImageFile;
  }
>;

export default function OurTeamPage({
  data,
}: OurTeamProps): ReactElement<OurTeamProps> {
  // const publishedTeamMembers = data.allFile.nodes
  //   .map((node) => node.childMarkdownRemark.frontmatter)
  //   .filter((member) => member.published);

  return (
    <OurCampLayout
      meta={{
        title: "Our Team",
        description:
          "You can feel confident that your students, staff and family members are in good hands when participating in any of our camp programs. Meet our Camp Yarramundi team and find out what they love most about their job.",
      }}
      bannerHeaderImage={data.bannerHeaderImage}
    >
      <div className="content-block">
        <h2>Our Team</h2>
        <p>
          We have a qualified management team who bring a wealth of experience
          spanning over 40 years. Our staff hold current qualifications in
          Outdoor Education and Training, ACF Safeguarding Children and Young
          People, Youth Work and First Aid/CPR.
        </p>
        <p>
          You can feel confident that your students, staff and family members
          are in good hands when participating in any of our camp programs.
        </p>
        {/* <p>
          Meet our Camp Yarramundi team and find out what they love most about
          their job.
        </p> */}
      </div>
      {/* {publishedTeamMembers.map((member, i) => (
        <TeamMemberRow key={member.name} member={member} isLeft={i % 2 === 0} />
      ))} */}
    </OurCampLayout>
  );
}

export const query = graphql`
  query OurTeamQuery {
    bannerHeaderImage: file(relativePath: { eq: "img/camp-team-bg.jpg" }) {
      ...GeneralPageLayout_bannerHeaderImage
    }
    allFile(filter: { relativeDirectory: { eq: "team-members" } }) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            name
            bio
            role
            yearsOfExperience
            published
            image {
              childImageSharp {
                fluid(maxWidth: 212, maxHeight: 212) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
